<template>
  <PreLoader :loading="isLoading" />
  <HeroPage />
</template>

<script setup>
import HeroPage from "@/components/HeroPage/HeroPage.vue";

import { onMounted } from "vue";

import PreLoader from "@/shared/PreLoader.vue";
import { usePreloader } from "@/composables/usePreloader";

const { isLoading, startLoading, stopLoading, waitForImages } = usePreloader();

onMounted(async () => {
  await startLoading();
  try {
    // Wait for any data loading and images
    await waitForImages();
  } catch (error) {
    console.error("Error loading contact page:", error);
  } finally {
    await stopLoading();
  }
});
</script>

<style></style>

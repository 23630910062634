// PreLoader.vue
<template>
  <Transition name="fade">
    <div v-if="loading" class="preloader">
      <!-- Import Google Font -->
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap"
        rel="stylesheet"
      />
      <div class="loader-content">
        <!-- Clean, modern background -->
        <div class="background">
          <div class="grid-lines"></div>
        </div>

        <!-- Modern centered logo container -->
        <div class="loader-container">
          <!-- Lightning bolt logo symbol -->
          <div class="logo-symbol">
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50" cy="50" r="40" class="logo-circle" />
              <!-- Main lightning bolt -->
              <path
                d="M50,20 L38,50 L50,50 L35,80"
                class="lightning-bolt main-bolt"
              />
              <!-- Secondary lightning bolt -->
              <path
                d="M60,20 L52,42 L62,42 L50,65"
                class="lightning-bolt secondary-bolt"
              />
              <!-- Power dot -->
              <circle cx="50" cy="50" r="6" class="power-dot" />
            </svg>
          </div>

          <!-- Company name with animated letters -->
          <div class="company-name">
            <span style="animation-delay: 0.1s">E</span>
            <span style="animation-delay: 0.15s">U</span>
            <span style="animation-delay: 0.2s">L</span>
            <span style="animation-delay: 0.25s">E</span>
            <span style="animation-delay: 0.3s">A</span>
            <span style="animation-delay: 0.35s">D</span>
            <span style="animation-delay: 0.4s">&nbsp;</span>
            <span style="animation-delay: 0.45s">E</span>
            <span style="animation-delay: 0.5s">L</span>
            <span style="animation-delay: 0.55s">E</span>
            <span style="animation-delay: 0.6s">C</span>
            <span style="animation-delay: 0.65s">T</span>
            <span style="animation-delay: 0.7s">R</span>
            <span style="animation-delay: 0.75s">I</span>
            <span style="animation-delay: 0.8s">C</span>
            <span style="animation-delay: 0.85s">A</span>
            <span style="animation-delay: 0.9s">L</span>
          </div>

          <!-- Modern loading bar -->
          <div class="loading-bar">
            <div class="loading-progress"></div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
const { defineProps } = require("vue");
defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
});
</script>

<style scoped>
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
  /* Fix blurriness with better rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translateZ(0);
  backface-visibility: hidden;
  /* Add font family */
  font-family: "Montserrat", "Segoe UI", -apple-system, BlinkMacSystemFont,
    sans-serif;
}

.loader-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Clean background with subtle grid */
.background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.grid-lines {
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 0, 0, 0.05) 1px, transparent 1px);
  background-size: 50px 50px;
  transform: perspective(500px) rotateX(60deg);
  animation: grid-move 15s linear infinite;
}

/* Modern centered loader */
.loader-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

/* Electric Logo Symbol */
.logo-symbol {
  width: 100px;
  height: 100px;
  animation: pulse 2s ease-in-out infinite;
  /* Fix SVG blurriness */
  transform: translateZ(0);
  will-change: transform;
}

.logo-circle {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
  opacity: 0.2;
}

.lightning-bolt {
  fill: none;
  stroke: #e10000;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  filter: drop-shadow(0 0 3px rgba(225, 0, 0, 0.5));
  animation: draw-lightning 1.5s ease-in-out forwards;
}

.main-bolt {
  stroke-width: 6;
}

.secondary-bolt {
  stroke-width: 4;
  animation-delay: 0.3s;
  opacity: 0.7;
}

.power-dot {
  fill: #000000;
  opacity: 0;
  transform-origin: center;
  animation: power-pulse 2s ease-out 0.6s infinite;
}

/* Company Name */
.company-name {
  display: flex;
  justify-content: center;
  font-size: 1.75rem;
  font-weight: 400; /* Normal font weight as in Tailwind */
  color: #000000;
  letter-spacing: 0.08em;
  text-align: center;
  text-transform: uppercase;
}

.company-name span {
  display: inline-block;
  opacity: 0;
  transform: translateY(20px);
  animation: text-reveal 0.6s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
}

/* Modern Loading Bar */
.loading-bar {
  width: 200px;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  overflow: hidden;
  margin-top: 0.5rem;
}

.loading-progress {
  height: 100%;
  width: 30%;
  background: linear-gradient(90deg, #9c0000, #e10000);
  border-radius: 2px;
  animation: progress 2s ease-in-out infinite;
}

/* Animations */
@keyframes grid-move {
  0% {
    transform: perspective(500px) rotateX(60deg) translateY(0);
  }
  100% {
    transform: perspective(500px) rotateX(60deg) translateY(50px);
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@keyframes draw-lightning {
  0% {
    stroke-dashoffset: 100;
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 0.8;
  }
}

@keyframes power-pulse {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

@keyframes text-reveal {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  60% {
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes progress {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(300%);
  }
}

/* Transition animations */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <div class="p-10 bg-gray-50">
    <div class="grid grid-cols-1 gap-6 lg:grid-cols-2">
      <!-- Contact Information -->
      <div
        class="space-y-6 lg:ml-24"
        data-aos="fade-right"
        data-aos-duration="2000"
      >
        <h2 class="text-3xl lg:text-5xl font-normal py-4">Let's Connect</h2>
        <div
          class="flex flex-col sm:flex-row items-start space-y-4 sm:space-y-0 sm:space-x-4 pt-3 group"
        >
          <span class="text-yellow-500 text-2xl">
            <img
              class="contact-img"
              :src="require('@/assets/contact-img.png')"
              alt="Contact"
            />
          </span>
          <div class="contact-text">
            <h3 class="text-2xl lg:text-3xl font-serif">Contact</h3>
            <div class="text-sm lg:text-base">
              <a
                href="mailto:info@euleadelectrical.co.uk"
                class="flex items-center"
              >
                <font-awesome-icon class="pr-1" :icon="['fas', 'envelope']" />
                info@euleadelectrical.co.uk
              </a>
              <a href="tel:+44 7876 612498" class="flex items-center">
                <font-awesome-icon class="pr-1" :icon="['fas', 'phone']" />
                +44 7876-612498
              </a>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col sm:flex-row items-start space-y-4 sm:space-y-0 sm:space-x-4 pt-3 group"
        >
          <span class="text-yellow-500 text-2xl">
            <img
              class="contact-img"
              :src="require('@/assets/office-img.png')"
              alt="Office"
            />
          </span>
          <div class="contact-text">
            <h3 class="text-2xl lg:text-3xl font-serif">Our Office</h3>
            <a href="#map" class="flex items-center">
              <font-awesome-icon class="pr-1" :icon="['fas', 'location-dot']" />
              25 Selsdon Avenue South Croydon CR2 6P
            </a>
          </div>
        </div>
        <div
          class="flex flex-col sm:flex-row items-start space-y-4 sm:space-y-0 sm:space-x-4 pt-3 group"
        >
          <span class="text-yellow-500 text-2xl">
            <img
              class="contact-img"
              :src="require('@/assets/workinghrs-img.png')"
              alt="Working Hours"
            />
          </span>
          <div class="contact-text">
            <h3 class="text-2xl lg:text-3xl font-serif">Working Hour</h3>
            <div class="flex items-center space-x-2">
              <font-awesome-icon :icon="['fas', 'clock']" />
              <span>Mon-Fri: 9am-5pm</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Contact Form -->
      <div class="pt-10">
        <form
          class="form space-y-4 bg-white p-4 sm:p-6 rounded shadow-md shadow-red-800"
          action="https://formspree.io/f/mjkvdrqn"
          method="POST"
          data-aos="fade-left"
          data-aos-duration="2000"
          @submit.prevent="handleSubmit"
        >
          <input
            type="text"
            name="name"
            v-model="formData.name"
            placeholder="Your Name"
            required
            class="w-full border border-gray-300 rounded p-3 focus:outline-none focus:ring-2 focus:ring-yellow-500"
          />
          <input
            type="email"
            name="email"
            v-model="formData.email"
            placeholder="Your Email"
            required
            class="w-full border border-gray-300 rounded p-3 focus:outline-none focus:ring-2 focus:ring-yellow-500"
          />
          <input
            type="tel"
            name="phone"
            v-model="formData.phone"
            placeholder="Your Phone"
            class="w-full border border-gray-300 rounded p-3 focus:outline-none focus:ring-2 focus:ring-yellow-500"
          />
          <textarea
            name="message"
            v-model="formData.message"
            placeholder="Your Message"
            rows="4"
            required
            class="w-full border border-gray-300 rounded p-3 focus:outline-none focus:ring-2 focus:ring-yellow-500"
          ></textarea>
          <button
            type="submit"
            class="w-full bg-orange-500 text-white font-semibold py-2 px-4 rounded hover:bg-orange-600"
            :disabled="isSubmitting"
          >
            {{ isSubmitting ? "Submitting..." : "Submit Now" }}
          </button>
        </form>
      </div>
    </div>

    <!-- Success Popup -->
    <transition name="popup">
      <div
        v-if="showSuccessPopup"
        class="success-popup fixed inset-0 flex items-center justify-center z-50"
        @click.self="showSuccessPopup = false"
      >
        <div
          class="popup-content bg-white rounded-lg shadow-xl p-8 max-w-md mx-4 relative"
          data-aos="zoom-in"
          data-aos-duration="500"
        >
          <button
            @click="showSuccessPopup = false"
            class="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          >
            <font-awesome-icon :icon="['fas', 'times']" />
          </button>
          <div class="text-center">
            <div class="success-checkmark">
              <div class="check-icon">
                <span class="icon-line line-tip"></span>
                <span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
                <div class="icon-fix"></div>
              </div>
            </div>
            <h3 class="text-2xl font-bold text-green-600 mb-2">Thank You!</h3>
            <p class="text-gray-700 mb-6">
              Your message has been sent successfully. We'll get back to you
              shortly.
            </p>
            <button
              @click="showSuccessPopup = false"
              class="bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 px-6 rounded"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ContactSection",
  data() {
    return {
      formData: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      isSubmitting: false,
      showSuccessPopup: false,
    };
  },
  methods: {
    async handleSubmit() {
      this.isSubmitting = true;

      try {
        // Send form data to Formspree
        const response = await fetch("https://formspree.io/f/mjkvdrqn", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.formData),
        });

        if (response.ok) {
          // Reset form
          this.formData = {
            name: "",
            email: "",
            phone: "",
            message: "",
          };

          // Show success popup
          this.showSuccessPopup = true;

          // Auto-hide popup after 5 seconds
          setTimeout(() => {
            this.showSuccessPopup = false;
          }, 5000);
        } else {
          console.error("Form submission failed");
          alert("Something went wrong. Please try again later.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        alert("Something went wrong. Please try again later.");
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style>
/* Animation for form */
.form {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  will-change: transform, box-shadow;
}

.form:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 12px 24px rgba(255, 0, 0, 0.3); /* Enhances the shadow on hover */
}

/* Animation for images */
.contact-img {
  transition: transform 0.3s ease, filter 0.3s ease;
  will-change: transform, filter;
}

.contact-img:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
}

/* Animation for text beside images */
.contact-text {
  transition: transform 0.3s ease, color 0.3s ease;
  will-change: transform, color;
}

.group:hover .contact-text {
  transform: translateX(10px); /* Moves the text slightly to the right */
}

/* Success Popup Animations */
.popup-enter-active,
.popup-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.popup-enter-from,
.popup-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.success-popup {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  transition: all 0.3s ease;
}

/* Checkmark animation */
.success-checkmark {
  width: 80px;
  height: 80px;
  margin: 0 auto 20px;
}

.check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #4caf50;
}

.check-icon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}

.check-icon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}

.check-icon::before,
.check-icon::after {
  content: "";
  height: 100px;
  position: absolute;
  background: #ffffff;
  transform: rotate(-45deg);
}

.icon-line {
  height: 5px;
  background-color: #4caf50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}

.icon-line.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}

.icon-line.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}

.icon-circle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid rgba(76, 175, 80, 0.5);
}

.icon-fix {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: #ffffff;
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

/* Accessibility and responsiveness */
@media (hover: hover) {
  .form:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 12px 24px rgba(255, 0, 0, 0.3);
  }
  .contact-img:hover {
    transform: scale(1.1);
    filter: brightness(1.2);
  }
  .group:hover .contact-text {
    transform: translateX(10px);
  }
}

.form:focus-within {
  box-shadow: 0 0 12px rgba(255, 165, 0, 0.5);
}

.contact-img:focus {
  outline: 2px solid #ff0;
  outline-offset: 4px;
}
</style>

import { ref } from "vue";

const isLoading = ref(true);
const FIXED_DURATION = 3000; // 3 seconds fixed duration

export function usePreloader() {
  const startLoading = () => {
    isLoading.value = true;
  };

  const stopLoading = () => {
    isLoading.value = false;
  };

  const waitForImages = async () => {
    return new Promise((resolve) => {
      const startTime = Date.now();

      const finishLoading = () => {
        const elapsedTime = Date.now() - startTime;
        const remainingTime = Math.max(FIXED_DURATION - elapsedTime, 0);

        // Always wait for the full duration
        setTimeout(() => {
          stopLoading();
          resolve();
        }, remainingTime);
      };

      const checkImages = () => {
        const images = Array.from(document.images);
        let loadedImages = 0;
        const totalImages = images.length;

        if (totalImages === 0) {
          finishLoading();
          return;
        }

        const imageLoaded = () => {
          loadedImages++;
          if (loadedImages === totalImages) {
            finishLoading();
          }
        };

        images.forEach((img) => {
          if (img.complete) {
            imageLoaded();
          } else {
            img.onload = imageLoaded;
            img.onerror = () => {
              console.warn(`Failed to load image: ${img.src}`);
              imageLoaded();
            };
          }
        });
      };

      // Initial check after a short delay to allow for DOM updates
      setTimeout(checkImages, 50);
    });
  };

  return {
    isLoading,
    startLoading,
    stopLoading,
    waitForImages,
  };
}
